import { Button, Grid, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import MainCard from '../../_Components/Common/Cards/MainCard';

function AdmissionFormDyanmic() {
    const [moduleData,setModuleData] = useState()
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [newFieldName, setNewFieldName] = useState(''); // State for the new field name input
    const [showNewFieldInput, setShowNewFieldInput] = useState(false); // State to toggle the new field input
    const nToken = localStorage.getItem("nToken");
    const getuserInfo = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(getuserInfo);
    const schoolcode = userInfo[0].institution;
    const userID = userInfo[0].id;
    const [searchParams] =useSearchParams(); 
    const title=   searchParams.get('type').replaceAll(' ','_');
    console.log(title);
    

    // Fetch initial data for fields
    useEffect(() => {
        const url = `${process.env.REACT_APP_nodewsPrefix}/viewInsertRows?userid=${userID}&title=${title}&schoolcode=${schoolcode}`;
        axios.get(url, {
            headers: {
                Authorization: `Bearer ${nToken}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            setModuleData(response.data);
            const initialFields = response.data.moduleData.mappingcolumn.customColumn;
            setData(initialFields);
            
            // Initialize formData with default values
            const initialFormData = initialFields.reduce((acc, field) => {
                acc[field] = ''; // Setting all fields to an empty string by default
                return acc;
            }, {});
            setFormData(initialFormData);
        }).catch((err) => console.error(err));
    }, [userID, schoolcode, nToken]);

    // Handle input change for form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        // Submit form data to the API
        // try {
        //     const response = await axios.post('your-api-endpoint', formData);
        //     console.log('Form submitted successfully:', response.data);
        // } catch (error) {
        //     console.error('Error submitting the form:', error);
        // }
    };

    // Handle adding more fields dynamically with a custom name
    const handleAddField = async() => {
        console.log(moduleData);
        
        if (newFieldName && !data.includes(newFieldName)) {
            setData([...data, newFieldName]); // Add the new field to the data array
            setFormData({
                ...formData,
                [newFieldName]: '' // Initialize the new field in formData
            });
            // const url = `${process.env.REACT_APP_nodewsPrefix}/createTableDynamic`;
            // let res = await axios.post(
            //   url,
            //   {
            //     title: moduleData.title.replace(/ /g, "_"),
            //     schoolcode: schoolcode,
            //     description: moduleData.description,
            //     customColumn: IDS,
            //     columnName: entry,
            //     edit: 1,
            //   },
            //   {
            //     headers: {
            //       Authorization: `Bearer ${nToken}`,
            //     },
            //   }
            // );
            setNewFieldName(''); // Clear the input field after adding
            setShowNewFieldInput(false); // Hide the input field after adding



        } else {
            alert('Please enter a valid and unique field name');
        }
    };

    // Show input field when "Add More Field" is clicked
    const handleShowInput = () => {
        setShowNewFieldInput(true);
    };

    return (
      
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                {data && data.map((field, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={field.replace('_', ' ').toUpperCase()}
                            name={field}
                            value={formData[field]}
                            onChange={handleChange}
                        />
                    </Grid>
                ))}

                {/* Show "Add More Field" button if the input field is not displayed */}
                {/* {!showNewFieldInput && (
                    <Grid item xs={12}>
                        <Button variant="contained" color="secondary" onClick={handleShowInput}>
                            Add More Field
                        </Button>
                    </Grid>
                )} */}

                {/* Conditionally render input for the new field name */}
                {/* {showNewFieldInput && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Enter New Field Name"
                                value={newFieldName}
                                onChange={(e) => setNewFieldName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" onClick={handleAddField}>
                                Add Field
                            </Button>
                        </Grid>
                    </>
                )} */}

                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    
    );
}

export default AdmissionFormDyanmic;
