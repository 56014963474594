import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  StyledInput,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { languages } from "../../App";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PushPinIcon from "@mui/icons-material/PushPin";
import Quiznavigation from "./Quiznavigation";
import { useNavigate } from "react-router";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import axios from "axios";

let answerAttempt = [];
let multichoiseVal;
let previousAttempt = [];
let isAttempted;

const QuizQuestions = ({ qdata, isLoading }) => {
  console.log("inder qdata", qdata);
  const token = localStorage.getItem("token");

  const attemptSummaryURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_attempt_summary&moodlewsrestformat=json&attemptid=${qdata.assesmentId}`;

  console.log("inder attemptSummaryURL", attemptSummaryURL);
  const [allAttemptedData, setAllAttemtptedData] = useState([]);
  const [allFlaged, setAllFlaged] = useState([]);

  const [flagChecked, setflagChecked] = useState();
  const navigation = useNavigate();

  let curLanguage = useContext(languages);

  const handlemultichoiceChange = (event) => {
    console.log(event.target.value, "xdxdxd 1");
    
    multichoiseVal = event.target.value;
  };
  /* 'noOfQuestion','questionType', 'questionText', 'answer', 'attempt','Answer saved', "qid"*/

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    str = str.replace(/(<([^>]+)>)/gi, "");
    str = str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ");
    return str;
  }

  function createHTML(str) {
    if (str.includes("img")) {
      return <div dangerouslySetInnerHTML={{ __html: str }} />;
    } else {
      return removeTags(str);
    }
  }
  const checkPreviousAttempt = () => {
    if (qdata.questionType === "multichoice") {
      let attemptedAnswerObj = qdata.answer.filter(
        (data) => data.checked === true
      );
      if (attemptedAnswerObj.length) {
        let attemptedAnswer = qdata.answerAttempt[0];
        previousAttempt[qdata.pageNo] = attemptedAnswer;
      }
    } else if (qdata.questionType === "multianswer") {
      previousAttempt[qdata.pageNo] = qdata.answerAttempt;
    } else if (qdata.questionType === "truefalse") {
      previousAttempt[qdata.pageNo] = qdata.answerAttempt[0];
    }
  };

  if (qdata.attempt === "Answer saved") {
    isAttempted = true;
    checkPreviousAttempt();
  } else {
    isAttempted = false;
  }

  const inputChangeHandler = () => {
    console.log("inputchangehandler");
  };

  function addInput(str) {
    let filterStr = removeTags(str);

    if (filterStr.includes("{#1}")) {
      filterStr = filterStr.replace(
        "{#1}",
        `<br><input  class="quizQuestInput" type="text" id="in1" value=${
          isAttempted ? previousAttempt[qdata.pageNo][0] : ""
        }>`
      );
    }
    if (filterStr.includes("{#2}")) {
      filterStr = filterStr.replace(
        "{#2}",
        `<input  class="quizQuestInput" type="text" id="in2" value=${
          isAttempted ? previousAttempt[qdata.pageNo][1] : ""
        }>`
      );
    }
    if (filterStr.includes("{#3}")) {
      filterStr = filterStr.replace(
        "{#3}",
        `<input  class="quizQuestInput" type="text" id="in3" value=${
          isAttempted ? previousAttempt[qdata.pageNo][2] : ""
        }>`
      );
    }
    if (filterStr.includes("{#4}")) {
      filterStr = filterStr.replace(
        "{#4}",
        `<input  class="quizQuestInput" type="text" id="in4" value=${
          isAttempted ? previousAttempt[qdata.pageNo][3] : ""
        }>`
      );
    }
    if (filterStr.includes("{#5}")) {
      filterStr = filterStr.replace(
        "{#5}",
        `<input class="quizQuestInput" type="text" id="in5" value=${
          isAttempted ? previousAttempt[qdata.pageNo][4] : ""
        }>`
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: filterStr }} />;
  }

  const getAnswerValue = () => {
    if (
      qdata.questionType === "multichoice" ||
      qdata.questionType === "truefalse"
    ) {
      if (multichoiseVal) {
        answerAttempt[qdata.pageNo] = [];
        answerAttempt[qdata.pageNo].push(multichoiseVal);
        multichoiseVal = undefined;
      }
    } else if (qdata.questionType === "multianswer") {
      if (document.querySelector("#in1")?.value) {
        answerAttempt[qdata.pageNo] = [];
        if (document.querySelector("#in1")) {
          answerAttempt[qdata.pageNo].push(
            document.querySelector("#in1")?.value
          );
        }
        if (document.querySelector("#in2")) {
          answerAttempt[qdata.pageNo].push(
            document.querySelector("#in2")?.value
          );
        }
        if (document.querySelector("#in3")) {
          answerAttempt[qdata.pageNo].push(
            document.querySelector("#in3")?.value
          );
        }
        if (document.querySelector("#in4")) {
          answerAttempt[qdata.pageNo].push(
            document.querySelector("#in4")?.value
          );
        }
        if (document.querySelector("#in5")) {
          answerAttempt[qdata.pageNo].push(
            document.querySelector("#in5")?.value
          );
        }
      }
    }
  };

  const [attSaved, setAttSaved] = useState(0);

  const saveAttempt = (type) => {
    console.log("xdxdxd 1", type, multichoiseVal);
    if (type === "multichoice") {
      console.log("xdxdxd 2 multichoice");
      let linkcreated = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_save_attempt&moodlewsrestformat=json&attemptid=${qdata.assesmentId}&data[0][name]=${qdata.formElements[0].name}&data[0][value]=${qdata.formElements[1].value}&data[1][name]=${qdata.formElements[2].name}&data[1][value]=${qdata.formElements[3].value}&data[2][name]=${qdata.formElements[4].name}&data[2][value]=${multichoiseVal}`;

      axios.get(linkcreated).then((response) => {
        console.log("xdxdxd", response.data);
      });
    } else if (type === "multianswer") {
      console.log("xdxdxd 2 multianswer");
      let id;
      let valueInp = [];

      let noOfInput = (qdata.formElements.length - 4) / 2;

      for (let i = 1; i <= noOfInput; i++) {
        id = `#in${i}`;
        valueInp[i - 1] = document.querySelector(id).value;
        console.log("INDER VI" + valueInp);
      }

      let linkcreated = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_save_attempt&moodlewsrestformat=json&attemptid=${qdata.assesmentId}&data[0][name]=${qdata.formElements[0].name}&data[0][value]=${qdata.formElements[1].value}&data[1][name]=${qdata.formElements[2].name}&data[1][value]=${qdata.formElements[3].value}`;

      for (let i = 1; i <= noOfInput; i++) {
        // i = 1
        //`&data[2][name]=${qdata.formElements[4].name}&data[2][value]=${valueInp[0]}`;

        // i = 2
        //`&data[3][name]=${qdata.formElements[6].name}&data[3][value]=${valueInp[1]}`;

        // i = 3
        //`&data[4][name]=${qdata.formElements[8].name}&data[4][value]=${valueInp[2]}`;

        linkcreated += `&data[${i + 1}][name]=${
          qdata.formElements[i * 2 + 2].name
        }&data[${i + 1}][value]=${valueInp[i - 1]}`;
      }

      axios.get(linkcreated).then((response) => {
        console.log("xdxdxd 3 multianswer", response.data);
      });
    } else if (type === "truefalse") {
      console.log("xdxdxd 2 truefalse");

      console.log(
        `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_save_attempt&moodlewsrestformat=json&attemptid=${qdata.assesmentId}&data[0][name]=${qdata.formElements[0].name}`
      );

      let linkcreated = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_save_attempt&moodlewsrestformat=json&attemptid=${qdata.assesmentId}&data[0][name]=${qdata.formElements[0].name}&data[0][value]=${qdata.formElements[1].value}&data[1][name]=${qdata.formElements[2].name}&data[1][value]=${qdata.formElements[3].value}&data[2][name]=${qdata.formElements[4].name}&data[2][value]=${multichoiseVal}`;

      axios.get(linkcreated).then((response) => {
        console.log("xdxdxd 3 truefalse", response.data);
      });
    }

    setAttSaved((prevState) => prevState + 1);
  };

  const goToPrevious = () => {
    qdata.previousQuestion();
    saveAttempt(qdata.questionType);
  };
  const goToNext = () => {
    qdata.nextQuestion();
    saveAttempt(qdata.questionType);
  };

  console.log(qdata);
  let navigationButtons = [];

  useEffect(() => {
    try {
      axios.get(attemptSummaryURL).then((response) => {
        console.log("inder uyuyuy", attemptSummaryURL);
        if (response.statusText !== "OK") {
          throw new Error("data not found");
        }

        console.log("getRes", response.data);

        const attVal = response.data.questions.map((data) => {
          return data.status;
        });

        const flagVal = response.data.questions.map((data) => {
          return data.flagged;
        });
        setAllAttemtptedData(attVal);
        setAllFlaged(flagVal);
      });
    } catch (err) {
      console.log("data not found");
    }

    setflagChecked(qdata.answerFlaged);
  }, [attemptSummaryURL, qdata.answerFlaged, attSaved]);

  console.log("IND", allAttemptedData);

  for (let i = 0; i < qdata.noOfQuestion; i++) {
    navigationButtons.push(
      <Quiznavigation
        qNo={i}
        from="attempt"
        isAttempted={allAttemptedData[i]}
        activeQ={qdata.pageNo}
        setPageNo={qdata.setPageNo}
        isFlaged={allFlaged[i]}
        key={i}
        setAttSaved={setAttSaved}
      />
    );
  }

  const submitHandler = () => {
    if (qdata.pageNo === qdata.noOfQuestion - 1) {
      saveAttempt(qdata.questionType);
    }
    navigation("attemptSummary", {
      state: {
        token: token,
        assessmentId: qdata.assesmentId,
        quizID: qdata.quizID,
      },
    });
  };


  const flagChanged = (e) => {
    let flag = e.target.checked ? 1 : 0;
    console.log("flag", flag);

    setflagChecked(e.target.checked);

    console.log("flag33 value", e.target.checked);

    const flagsetURL = `${
      process.env.REACT_APP_wsPrefix
    }/webservice/rest/server.php?wstoken=${token}&wsfunction=core_question_update_flag&qaid=${
      qdata.qaid
    }&qubaid=${qdata.qubaid}&slot=${qdata.pageNo + 1}&checksum=${
      qdata.checksum
    }&newstate=${flag}&questionid=${qdata.qid}`;

    axios.get(flagsetURL).then((response) => {
      console.log(response.data);
      console.log(flagsetURL);
    });
    //qdata.flagQuestion();
  };

  return (
    <>
      <Grid item xs={12} lg={9} className="assesmentSingQuest">
        <Card
          maxWidth="xl"
          sx={{
            p: 1,
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
            textAlign: "center",
            position: "relative",
          }}
          className="customCard assesmentQuestWrapper"
        >
          <Box
            className={`Flagged ${
              flagChecked ? "flagChecked" : "flagNotChecked"
            }`}
          >
            <input
              type="checkbox"
              id="myCheckbox1"
              onChange={flagChanged}
              checked={flagChecked}
            />
            <label for="myCheckbox1">
              <BookmarkAddedIcon />
            </label>
          </Box>
          {qdata.questionType === "multichoice" && (
            <Box className="assessmentQuest">
              <p>{createHTML(qdata.questionText)}</p>

              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="quizQuest1"
                defaultValue={isAttempted ? previousAttempt[qdata.pageNo] : ""}
                className="radioOptions"
                row
              >
                {qdata.answer.map((option, indx) => {
                  return (
                    <FormControlLabel
                      value={indx}
                      control={<Radio />}
                      label={createHTML(option.answer)}
                      key={option.id}
                      onChange={handlemultichoiceChange}
                    />
                  );
                })}
              </RadioGroup>
            </Box>
          )}

          {qdata.questionType === "multianswer" && (
            <Box className="assessmentQuest">
              <p>{addInput(qdata.questionText)}</p>
            </Box>
          )}

          {qdata.questionType === "truefalse" && (
            <Box className="assessmentQuest">
              <p>{createHTML(qdata.questionText)}</p>
              <Box>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label1"
                  name="quizQuest2"
                  defaultValue={
                    isAttempted ? previousAttempt[qdata.pageNo] : ""
                  }
                  className="radioOptions"
                  row
                >
                  {console.log(previousAttempt[qdata.pageNo])}
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="True"
                    onChange={handlemultichoiceChange}
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="False"
                    onChange={handlemultichoiceChange}
                  />
                </RadioGroup>
              </Box>
            </Box>
          )}

          <Box className="quizNextBack">
            <Box>
              <Button
                color="secondaryColor"
                variant="contained"
                size="small"
                startIcon={<ArrowBackIosIcon />}
                onClick={goToPrevious}
                disabled={qdata.pageNo === 0}
                className="formsBtns"
              >
                {curLanguage.previous}
              </Button>
            </Box>
            <Box className="noOfQuest">{`${qdata.pageNo + 1}/${
              qdata.noOfQuestion
            }`}</Box>
            <Box>
              {qdata.pageNo === qdata.noOfQuestion - 1 ? (
                <Button
                  color="secondaryColor"
                  variant="contained"
                  size="small"
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={submitHandler}
                  className="formsBtns"
                >
                  {curLanguage.submit}
                </Button>
              ) : (
                <Button
                  color="secondaryColor"
                  variant="contained"
                  size="small"
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={goToNext}
                  className="formsBtns"
                >
                  {curLanguage.next}
                </Button>
              )}
            </Box>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12} lg={3}>
        <Box className="Quiznavigation-inner roudned_box_assessment">
          <Grid>
            <Typography
              variant="h3"
              sx={{
                color: "Quiznavigation.textColor",
                borderBottom: "3px solid",
                borderColor: "Quiznavigation.textColor",
              }}
            >
              {curLanguage.QuizNavigation}
            </Typography>
          </Grid>
          <Grid container className="Quiznavigation-box" gap={1}>
            {navigationButtons}
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Button
              color="secondaryColor"
              variant="contained"
              size="small"
              sx={{ marginBottom: "15px" }}
              onClick={submitHandler}
              className="formsBtns"
            >
              {curLanguage.submit}
            </Button>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default QuizQuestions;
