import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function CreateTeacherModule() {
  const [getModulesData, setGetModulesData] = useState()
  const nToken = localStorage.getItem("nToken");
    const getuserInfo = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(getuserInfo);
    const schoolcode = userInfo[0].institution;
    const userID = userInfo[0].id;
    console.log(userID);
    const [searchParams] =useSearchParams(); 
    const title=   searchParams.get('title').replaceAll(' ','_');
    console.log(title);
  useEffect(() => {
    const url = `${process.env.REACT_APP_nodewsPrefix}/viewInsertRows?userid=${userID}&title=${title}&schoolcode=${schoolcode}`;
    console.log(url);

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${nToken}`,
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      console.log(response, "rajji");
      setGetModulesData(response.data);
    }).catch((err) => console.error(err));
  }, [])
  return (
    <div>CreateTeacherModule</div>
  )
}

export default CreateTeacherModule
