import { Box, Tooltip, Typography } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IoIosLock } from "react-icons/io";
import axios from "axios";
import { useState } from "react";
import Loader from "../../_Components/Utils/loader/Loader";
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";

const AssetContent = ({
  moduleValue,
  presentAssignment,
  imgName,
  checkActiveAssignments,
  clickOpenForm,
  courseID,
  department,
  //assesmentDeleted
}) => {
  console.log(moduleValue.instance, "ind111");
  

  const nToken = localStorage.getItem("nToken");
  const token = localStorage.getItem("token");
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();


  const deleteAssessments = (e, moduleID) => {
    //assesmentDeleted(false)
    setIsDeleted(false);
    setIsDeleting(true);
    e.stopPropagation();
    axios.post(`${process.env.REACT_APP_nodewsPrefix}/quizDelete`,
      {
        "url": "https://test-lms.ratnasagar.com/",
        "token": token,
        "course_module_id": parseInt(moduleID),
        //"course": courseID,
        //"section": section
      },
      {
        headers: {
          Authorization: `Bearer ${nToken}`,
          'Content-Type': 'application/json'
        },
      })
      .then(response => {
        //assesmentDeleted(true)
        setIsDeleting(false);
        setIsDeleted(true);
        console.log(response.status);
        
        /* setTimeout(() => {
          setIsDeleted(false);
        }, 5000) */
      })
      .catch((err) => {
        console.log(err.message)
        setIsDeleted("error");
        setTimeout(() => {
          setIsDeleted(false);
        }, 5000)
      });
    console.log("delete")
  }

  const editAssessments = (e, moduleID) => {
    e.stopPropagation();
    console.log("editAssessments", moduleID);
    navigate(`/editquiz?editing=true&moduleID=${moduleValue.instance}&courseid=${courseID}`);
  }

  if (isDeleting) {
    return (
      <Box className="assetsBoxes" onClick={clickOpenForm}><Loader /></Box>
    )
  } else if (isDeleted) {

    if (isDeleted === "error") {
      return (<Box className="assetsBoxes" onClick={clickOpenForm}>
        <CancelIcon />Not Deleted
      </Box>)
    }
    return (<Box className="assetsBoxes" onClick={clickOpenForm}>
      <DoneIcon />Deleted
    </Box>)
  } else {
    return (
      <Box className="assetsBoxes" onClick={clickOpenForm}>
        {presentAssignment > 0 && !checkActiveAssignments && (
          <Box className="lockedBg">
            <IoIosLock size={30} />
          </Box>
        )}
        <Box
          className="titleFace"
          sx={{
            color: "textsColor.secColor",
          }}
        >
          {moduleValue.name}
        </Box>
        <Box className={`imgBox`}>
          {console.log(moduleValue.modname)}
          {moduleValue.modname === "quiz" ? <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/courseIcons/assessment.png`}
            className="assetOverlayImg"
            alt={imgName}
          /> : <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/courseIcons/${imgName}.png`}
            className="assetOverlayImg"
            alt={imgName}
          />}

        </Box>
        <Box className={`overlayBox ${moduleValue.modname === "quiz" && "quizOverlay"}`}>
          {console.log(moduleValue)}

          {moduleValue.modname === "quiz" && (department === "pedagogy" || department === "admin") &&
            <>
              <Box className="delete" onClick={e => { deleteAssessments(e, moduleValue.id) }}><Tooltip title="Delete"><DeleteForeverIcon /></Tooltip></Box>
              <Box className="edit" onClick={e => { editAssessments(e, moduleValue.id) }}><Tooltip title="Edit"><EditIcon /></Tooltip></Box>
            </>
          }
          <Box className="topIcon">{moduleValue.name}</Box>
          {presentAssignment > 0 && !checkActiveAssignments && (
            <Typography className="dataInfo">
              Locked until the assignment is completed.
            </Typography>
          )}
        </Box>

      </Box>
    );
  }

};

export default AssetContent;
