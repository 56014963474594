import { Box, Grid, Typography } from "@mui/material";
import MainCard from "../../_Components/Common/Cards/MainCard";
import { useLocation } from "react-router-dom";
import CreatePDF from "../../_Components/Common/pdf/CreatePDF";
import { useEffect, useState } from "react";
import axios from "axios";
import Activities from "./Activities";
import ScoredActivity from "./ScoredActivity";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";
import { Scrollbar } from "react-scrollbars-custom";

const StudentProgress = () => {  
  const userinfoObject = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState([]);
  const [scoredActivities, setScoredActivities] = useState([]);
  const [nonScoredActivities, setNonScoredActivities] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const stdId = searchParams.get("id");
  const studentName = searchParams.get("studentName");
  const courseId = searchParams.get("courseid");
  const coursenames = searchParams.get("coursename");
  const classSection =
    searchParams.get("stdclass") + searchParams.get("section");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reportDataURL = `${process.env.REACT_APP_nodewsPrefix}/viewActivityCompletion`;
        const response = await axios.post(
          reportDataURL,
          {
            course_id: courseId,
            userid: stdId,
            department: userinfoObject[0]?.department,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        const data = response.data.course_data;
        setActivityData(data);
        const scored = [];
        const nonScored = [];

        data?.forEach((section) => {
          if (Array.isArray(section?.scorm_data)) {
            section?.scorm_data.forEach((activity) => {
              if (coursenames === "English") {
                if (activity.scromtype === 1) {
                  scored.push({
                    section_name: section.section_name,
                    activity,
                  });
                } else {
                  nonScored.push({
                    section_name: section.section_name,
                    activity,
                  });
                }
              } else {
                if (
                  activity.scromtype === 1 &&
                  activity.scrom_name !== "Dictionary"
                ) {
                  scored.push({
                    section_name: section.section_name,
                    activity,
                  });
                } else {
                  nonScored.push({
                    section_name: section.section_name,
                    activity,
                  });
                }
              }
            });
          }
        });

        setScoredActivities(scored);
        setNonScoredActivities(nonScored);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [courseId, stdId, coursenames]);

  return (
    <MainCard
      cardTitle={`${coursenames} ${classSection}`}
      customClass="progressCard"
      additionalData={
        (scoredActivities.length > 0 ||  nonScoredActivities.length > 0) &&
        <CreatePDF
          id={studentName}
          fileName="progress_report"
          orientation={"portrait"}
          btnTitle={"Download Report"}
          pdfHeading={"Report"}
        />
      }
    >
      <Typography className="progressHeader" variant="h6">
        Progress Report
      </Typography>
      {loading ? (
        <CircularLoader />
      ) : (
        <Scrollbar style={{ maxWidth: "100%", height: `calc(70vh - 30px)` }}>
          <Box id={studentName} className="progressContent">
            <Box display={"flex"} alignItems={"center"} gap={2} p={1}>
              <Typography>
                <b>Name:</b> {studentName}
              </Typography>
              <Typography>
                <b>Class:</b> {classSection}
              </Typography>
            </Box>

            <Box className="activitySec">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ScoredActivity
                    activity={scoredActivities}
                    heading="Scored Activity"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Activities
                    activity={nonScoredActivities}
                    heading="Non Scored Activity"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Scrollbar>
      )}
    </MainCard>
  );
};

export default StudentProgress;
