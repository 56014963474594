import { Box, Button, Card, Container, Grid, TextField, Typography } from "@mui/material"
import BackButton from "../../../_Components/Common/BackBtn/BackBtn"
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import "./CreateQuiz.scss"
import Loader from "../../../_Components/Utils/loader/Loader";
import QuestionListing from "./questionListing";



function CreateQuiz() {
    const navigate = useNavigate();

    const [SearchParams] = useSearchParams();



    const courseID = SearchParams.get("courseid");
    const section = SearchParams.get("section");
    const moduleID = SearchParams.get("moduleID")
    const editQ = SearchParams.get("editing")
    const userId = JSON.parse(localStorage.getItem("userInfo"))[0].id;
    const token = localStorage.getItem("token");
    const nToken = localStorage.getItem("nToken");
    const [qDeleted, setQDeleted] = useState(false);
    const [isAttempted, setIsAttempted] = useState(false);





    const getQuizData = () => {
        console.log("getQuizData");
        const getQuizDataURL = `${process.env.REACT_APP_nodewsPrefix}/quizview?quizID=${moduleID}&courseID=${courseID}`;

        axios.get(getQuizDataURL,
            {
                headers: {
                    Authorization: `Bearer ${nToken}`,
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                console.log(response.data, "ind1111")
                let finalData = response.data.quizData_resp[0];
                console.log(finalData)

                setCreateQuizData(prevData => {
                    return {
                        ...prevData, name: finalData.name, description: finalData.description, gradepass: finalData.gradepass, timeopen
                            : finalData.timeopen, timeclose: finalData.timeclose, categoryID: response.data.quizCategoryID
                    }
                })
            }).catch(err => {
                console.log("error")
            })
    }

    const ifQuizAttempted = () => {
        let ifAttemptedURL = `${process.env.REACT_APP_nodewsPrefix}/quizAttempt?quizID=${moduleID}`;
        axios.get(ifAttemptedURL,
            {
                headers: {
                    Authorization: `Bearer ${nToken}`,
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                console.log(response.data.quizCount);
                if(response.data.quizCount>0){
                    setIsAttempted(true)
                }else{
                    setIsAttempted(false);
                }
            })
    }

    //console.log(userId, courseID, section, "ind77");

    useEffect(() => {
        console.log("useEffect")
        if (editQ === "true") {
            getQuizData();
            ifQuizAttempted();
        }
    }, [editQ]);


    const getDateFormated = () => {
        const currentDate = new Date();
        let cDate = currentDate.getDate();
        if (cDate < 10) {
            cDate = '0' + cDate
        };
        let cMonth = currentDate.getMonth() + 1;
        if (cMonth < 10) {
            cMonth = '0' + cMonth
        };
        let cYear = currentDate.getFullYear();
        let cHours = currentDate.getHours();
        let cMinutes = currentDate.getMinutes();
        if (cMinutes < 10) {
            cMinutes = '0' + cMinutes
        };
        console.log(`${cYear}-${cMonth}-${cDate}T${cHours}:${cMinutes}`)

        return `${cYear}-${cMonth}-${cDate}T${cHours}:${cMinutes}`;
    };

    const getDateforJason = cDate => {
        let year = cDate.split("-")[0];
        let month = cDate.split("-")[1];
        let date = cDate.split("-")[2].split("T")[0];
        let hrs = cDate.split("-")[2].split("T")[1].split(":")[0];
        let mints = cDate.split("-")[2].split("T")[1].split(":")[1];

        let fullDate = `${year}-${month}-${date} ${hrs}:${mints}`;

        return fullDate;
    }

    const defaultQuizData = {
        "url": "https://test-lms.ratnasagar.com/",
        "token": token,
        "name": "",
        "description": "",
        "questionsperpage": 1,
        "shuffleanswers": 1,
        "sequence": 1,
        "gradepass": "",
        "gradecat": 5,
        "attempts": 1,
        "course": parseInt(courseID),
        "section": parseInt(section),
        "timeopen": getDateforJason(getDateFormated()),
        "timeclose": getDateforJason(getDateFormated()),
        "timecreated": 1728384695,
        "timelimit": 36000,
        "timemodified": 0,
        "grademethod": 2,
        "userid": parseInt(userId),
        "add": 0,
        "feedbacktext": ["100% feedback", "100% feedback"],
        "feedbackboundaries": [50, 10],
    }

    console.log(defaultQuizData);

    const [createQuizData, setCreateQuizData] = useState(defaultQuizData);
    const [quizCreated, setQuizCreated] = useState(false);
    const [quizLoading, setQuizLoading] = useState(false);


    const [quizMessage, setQuizMessage] = useState({});

    let newQuizID;
    let catID;

    const createQuizHandler = e => {
        setQuizLoading(true);
        e.preventDefault();

        const createQuizURL = `${process.env.REACT_APP_nodewsPrefix}/quizcreate`
        //const createQuizURL = `http://10.4.40.20:4000/quizcreate`
        console.log("form submit", createQuizData, createQuizURL, token, nToken);

        axios.post(createQuizURL, createQuizData, {
            headers: {
                Authorization: `Bearer ${nToken}`,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            console.log(response.data, "ind111");

            setQuizLoading(false);
            setQuizCreated(true);
            setQuizMessage({ message: "Assessment created successfully", status: "success" });
            newQuizID = parseInt(response.data.quizData.quizID);
            catID = parseInt(response.data.quizData.question_categoryID);

            navigate(`/addquestion?quizID=${newQuizID}&catID=${catID}&courseID=${courseID}`);


        }).catch(err => {
            setQuizLoading(false);
            setQuizCreated(false);
            setQuizMessage({ message: "Some Problem assessment not created", status: "error" })
        });
    }

    function createTimestamp(dateString) {
        // Create a new Date object from the provided date string
        const date = new Date(dateString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date format");
        }

        // Return the timestamp (in milliseconds)
        return date.getTime() / 1000;
    }


    const convertToTimestamp = date => {
        if (date.includes("T")) {
            return createTimestamp(date)
        } else {
            const dateArr = date.split(" ");
            dateArr.splice(1, 0, "T");
            return createTimestamp(dateArr.join(""))
        }
    }

    /* {
        "token": "dc81ac01c88ed13ea16376828e5a9830",
        "url": "https://test-lms.ratnasagar.com/",
          "name": "New Form with questions EDIT 333",
          "description": "DESC",
          "gradepass": "20.00000",
          "timeopen": 1729831620,
          "timeclose": 1730004420,
          "timelimit": 10,
          "courseID": 187,
          "quizID": "1990"
        } */

    const editQuizHandler = e => {
        e.preventDefault();
        let open = convertToTimestamp(createQuizData.timeopen);
        let close = convertToTimestamp(createQuizData.timeclose);
        setQuizLoading(true)
        axios.put(
            "http://10.4.40.20:4000/quizupdate",
            {
                "url": "https://test-lms.ratnasagar.com/",
                "token": token,
                "name": createQuizData.name,
                "description": createQuizData.description,
                "gradepass": createQuizData.gradepass,
                "timeopen": open,
                "timeclose": close,
                "timelimit": (createQuizData.timelimit) / 3600,
                "quizID": moduleID,
                "courseID": courseID,
            },
            {
                headers: {
                    Authorization: `Bearer ${nToken}`,
                },
            }
        )
            .then(response => {
                setQuizLoading(false);
                setQuizMessage({ message: "Assessment updated successfully", status: "success" });
                setTimeout(() => {
                    setQuizMessage({});
                }, 3000);
            })
            .catch(error => {
                setQuizMessage({ message: "Some Problem assessment not updated", status: "error" })
            })

    }

    const handleQuizCreateInputChange = (e) => {
        if (e.target.name === "timeclose" || e.target.name === "timeopen") {
            console.log(1);

            let getDate = getDateforJason(e.target.value)

            setCreateQuizData(prevData => {
                return { ...prevData, [e.target.name]: getDate }
            })

        } else if (e.target.name === "timelimit") {
            console.log(1);
            setCreateQuizData(prevData => {
                return { ...prevData, [e.target.name]: e.target.value * 3600 }
            })
        } else {
            console.log(3);
            setCreateQuizData(prevData => {
                return { ...prevData, [e.target.name]: e.target.value }
            })
        }

    }



    return (
        <div className="dashboardMain">
            {console.log(createQuizData, qDeleted, "inder2234")}
            <Container maxWidth="xl">
                <Grid item md={12} sm={12}>
                    <Card
                        maxWidth="xl"
                        sx={{
                            mb: 4,
                            backgroundColor: "cardColor.main",
                            color: "cardColor.contrast",
                        }}
                        elevation={5}
                        className="customCard"
                    >
                        <Typography
                            className="title"
                            variant="h5"
                            px={2}
                            py={1}
                            sx={{ borderColor: "borderLine.main" }}
                        >
                            <Box className="titleHead">
                                <BackButton />
                                {editQ === "true" ? <Box>
                                    Edit Assesment
                                </Box> : <Box>
                                    Create Assesment
                                </Box>}
                            </Box>
                        </Typography>
                        {isAttempted && <Box sx={{ m: 3 }}>The student attempted this quiz and it can no longer be edited.</Box>}
                        <Box className="customCardContent" p={2}>
                            <form onSubmit={editQ === "true" ? editQuizHandler : createQuizHandler}>
                                <Grid container spacing={3} sx={{ mb: 3 }}>
                                    <Grid item md={5} sx={12} >
                                        <TextField
                                            label="Assessment Name"
                                            labelId="assessmentname"
                                            id="formcode-select"
                                            name="name"
                                            required
                                            sx={{ width: "100%" }}
                                            onChange={handleQuizCreateInputChange}
                                            disabled={quizCreated || quizLoading || isAttempted}
                                            value={createQuizData.name}
                                        />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                        <TextField
                                            label="Assessment Description"
                                            labelId="assessmentdescription"
                                            id="formcode-select"
                                            name="description"
                                            required
                                            sx={{ width: "100%" }}
                                            onChange={handleQuizCreateInputChange}
                                            disabled={quizCreated || quizLoading || isAttempted}
                                            value={createQuizData.description}
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <TextField
                                            label="Grade Pass"
                                            id="formcode-select"
                                            name="gradepass"
                                            type="number"
                                            required
                                            sx={{ width: "100%" }}
                                            onChange={handleQuizCreateInputChange}
                                            disabled={quizCreated || quizLoading || isAttempted}
                                            value={createQuizData.gradepass !== "" && Math.trunc(createQuizData.gradepass)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    {console.log(createQuizData)}
                                    <Grid item md={5} xs={12}>
                                        <TextField
                                            id="openthequiz"
                                            label="Open the quiz"
                                            type="datetime-local"
                                            name="timeopen"
                                            value={createQuizData.timeopen}
                                            sx={{ width: "100%" }}
                                            onChange={handleQuizCreateInputChange}
                                            required
                                            disabled={quizCreated || quizLoading || isAttempted}
                                        />

                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                        <TextField
                                            id="closethequiz"
                                            label="Close the quiz"
                                            type="datetime-local"
                                            name="timeclose"
                                            sx={{ width: "100%" }}
                                            value={createQuizData.timeclose}
                                            onChange={handleQuizCreateInputChange}
                                            disabled={quizCreated || quizLoading || isAttempted}
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <TextField
                                            label="Time Limit"
                                            id="timelimit"
                                            name="timelimit"
                                            value={(createQuizData.timelimit) / 3600}
                                            type="number"
                                            required
                                            sx={{ width: "100%" }}
                                            onChange={handleQuizCreateInputChange}
                                            disabled={quizCreated || quizLoading || isAttempted}
                                        />
                                    </Grid>
                                </Grid>
                                {quizMessage !== "" && <Box className={`quizMessage ${quizMessage.status}`} sx={{ mt: 3 }}>{quizMessage.message}</Box>}
                                {quizLoading && <Loader />}
                                <Box sx={{ mt: 3 }}>
                                    <Button disabled={quizCreated || isAttempted} variant="contained" type="submit">{editQ === "true" ? "Edit" : "Create"}</Button>
                                </Box>

                            </form>
                        </Box>

                        {editQ === "true" && (!isAttempted && <QuestionListing categoryID={createQuizData.categoryID} />)}
                    </Card>
                </Grid>
            </Container>
        </div>
    )
}

export default CreateQuiz
