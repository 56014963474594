import React from "react";
import { useRoutes } from "react-router-dom";
import MainLayout from "../../_Layout/MainLayout";
import Home from "../../_pages/Home/Home";
import Notes from "../../_pages/Notes/Notes";
import Dashboard from "../../_pages/Dashboard/Dashboard";
import Attendance from "../../_pages/Attendance/Attendance";
import ProtectedRoute from "./ProtectedRoute";
import Assignments from "../../_pages/ViewAssessment/Assignments";
import GetCourseContent from "../../_pages/GetCourseContent/GetCourseContent";
import ModAccessInformation from "../../_pages/Modules/ModAccessInformation";
import AddCertificate from "../../_pages/Certificate/AddCertificate";
import FeeCollection from "../../_pages/Fees/FeeCollection";
import Calendar from "../../_pages/calendar/Calendar";
import UserProfileDetails from "../../_pages/userProfile/UserProfileDetails";
import Doubt from "../../_pages/Doubts/Doubt";
import Page404 from "../../_pages/page404/Page404";
import Unauthorized from "../../_pages/page404/Unauthorized";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import UserForgotPassword from "../ForgotPassword/UserForgotPassword";
import AssessmentAttempt from "../../_pages/assessmentAttempt/assessmentAttempt";
import AssignTimeTable from "../TimeTable/AssignTimeTable";
import QuizAttemptSummary from "../../_pages/Quiz/QuizAttemptSummary";

import QuizStudentList from "../../_pages/Quiz/QuizStudentList";
import QuizAttemptReview from "../../_pages/Quiz/QuizAttemptReview";
import StdCertificates from "../../_pages/Certificates_Awards/StdCertificates";

import Assignment from "../../_pages/ViewAssessment/Assignment";
import StudentProgressDetail from "../../_pages/ProgressReport/StudentProgressDetail";
import StudentProgress from "../../_pages/ProgressReport/StudentProgress";
import UserReports from "../../_pages/PrincipalUser/Reports/UserReports";
import Logs from "../Logs/Logs";
import StaffAttendance from "../../_pages/PrincipalUser/AdminAttendance/StaffAttendance";
import UserCertificate from "../../_pages/PrincipalUser/Certificate/UserCertificate";
import ComingSoon from "../Common/ComingSoon";
import AssignRole from "./Roles/AssignRole";
import ExamSheetCreator from "../../_pages/Planner/ExamSheetCreator";
import AnnualPlanner from "../../_pages/Planner/AnnualPlanner";
import TimeTable from "../TimeTable/TimeTable";
import Setting from "../../_pages/UserManagement/Setting";
import AttendanceFormat from "../../_pages/PrincipalUser/AdminAttendance/AttendanceFormat";
import SavedCertificates from "../../_pages/Certificate/SavedCertificates";
import CreateQuiz from "../../_pages/Quiz/createQuiz/CreateQuiz";
import Module from "../../_pages/CustomModule/Module";
import AddQuestions from "../../_pages/Quiz/createQuiz/AddQuestions";
import DateSheet from "../../_pages/DateSheet/DateSheet";
import AdminPermission from "./AdminPermission";
import DeveloperPermission from "../../_pages/UserManagement/DeveloperPermission";
import CreateModuleForm from "../Common/ReportCard/CreateModuleForm";
import AdmissionFormDyanmic from "../../_pages/AdmissionForm/AdmissionFormDyanmic";
import AdmissionTab from "../../_pages/AdmissionForm/AdmissionTab";
export const Router = (props) => {
  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        { path: "/", element: <Home /> },
        {
          path: "/devpermission",
          element: (
            <ProtectedRoute
              element={<DeveloperPermission />}
              requiredPermissions="setting"
            />
          ),
        },
        {
          path: "/permission",
          element: (
            <ProtectedRoute
              element={<AdminPermission />}
              requiredPermissions="setting"
            />
          ),
        },
        {
          path: "/logs",
          element: <Logs />,
        },
        {
          path: "/dashboard",
          element: (
            <ProtectedRoute
              element={<Dashboard />}
              requiredPermissions="dashboard"
            />
          ),
        },
        {
          path: "/notes",
          element: (
            <ProtectedRoute element={<Notes />} requiredPermissions="notes" />
          ),
        },
        {
          path: "/attendance",
          element: (
            <ProtectedRoute
              element={<Attendance />}
              requiredPermissions="attendance"
            />
          ),
        },
        {
          path: "/setting",
          element: (
            <ProtectedRoute
              element={<Setting />}
              requiredPermissions="adminStaff"
            />
          ),
        },
        {
          path: "/attendanceFormat",
          element: (
            <ProtectedRoute
              element={<AttendanceFormat />}
              requiredPermissions="adminStaff"
            />
          ),
        },

        // {
        //   path: "/studentAttendance",
        //   element: (
        //     <ProtectedRoute
        //       element={<StudentAttendance />}
        //       requiredPermissions="studentAttendance"
        //     />
        //   ),
        // },
        {
          path: "/assignments",
          element: (
            <ProtectedRoute
              element={<Assignments />}
              requiredPermissions="assignment"
            />
          ),
        },

        {
          path: "/assignment",
          element: (
            <ProtectedRoute
              element={<Assignment />}
              requiredPermissions="assignment"
            />
          ),
        },

        {
          path: "/GetCourseContent",
          element: (
            <ProtectedRoute
              element={<GetCourseContent />}
              requiredPermissions="GetCourseContent"
            />
          ),
        },

        {
          path: "/createquiz",
          element: (
            <ProtectedRoute
              element={<CreateQuiz />}
              requiredPermissions="GetCourseContent"
            />
          ),
        },
        {
          path: "/editquiz",
          element: (
            <ProtectedRoute
              element={<CreateQuiz />}
              requiredPermissions="GetCourseContent"
            />
          ),
        },
        {
          path: "/addquestion",
          element: (
            <ProtectedRoute
              element={<AddQuestions />}
              requiredPermissions="GetCourseContent"
            />
          ),
        },
        

        {
          path: "/GetCourseContent/allStudentsReport",
          element: (
            <ProtectedRoute
              element={<StudentProgressDetail />}
              requiredPermissions="GetCourseContent"
            />
          ),
        },
        {
          path: "/GetCourseContent/StudentReport",
          element: (
            <ProtectedRoute
              element={<StudentProgress />}
              requiredPermissions="GetCourseContent"
            />
          ),
        },
        {
          path: "/addCertificate",
          element: (
            <ProtectedRoute
              element={<AddCertificate />}
              requiredPermissions="certificate"
            />
          ),
        },
        {
          path: "/stdCertificate",
          element: (
            <ProtectedRoute
              element={<StdCertificates />}
              requiredPermissions="certificate"
            />
          ),
        },
        {
          path: "/savedCertificate",
          element: (
            <ProtectedRoute
              element={<SavedCertificates />}
              requiredPermissions="certificate"
            />
          ),
        },
        {
          path: "GetCourseContent/modAccessInformation/quizstudentlist/quizattemptreview",
          element: (
            <ProtectedRoute
              element={<QuizAttemptReview />}
              requiredPermissions="attemptReview"
            />
          ),
        },
        {
          path: "/calendar",
          element: (
            <ProtectedRoute
              element={<Calendar />}
              requiredPermissions="calendar"
            />
          ),
        },
        {
          path: "/profile",
          element: (
            <ProtectedRoute
              element={<UserProfileDetails />}
              requiredPermissions="profile"
            />
          ),
        },

        {
          path: "/doubt",
          element: (
            <ProtectedRoute element={<Doubt />} requiredPermissions="doubt" />
          ),
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "/user-forgot-password",
          element: <UserForgotPassword />,
        },
        {
          path: "/assessmentAttempt",
          element: (
            <ProtectedRoute
              element={<AssessmentAttempt />}
              requiredPermissions="assessmentattempt"
            />
          ),
        },
        {
          path: "/assignTimeTable",
          element: (
            <ProtectedRoute
              element={<AssignTimeTable />}
              requiredPermissions="assigntimeTable"
            />
          ),
        },

        {
          path: "/setting",
          element: (
            <ProtectedRoute
              element={<Setting />}
              requiredPermissions="userManagement"
            />
          ),
        },
        {
          path: "/timetable",
          element: (
            <ProtectedRoute
              element={<TimeTable />}
              requiredPermissions="timetable"
            />
          ),
        },
        {
          path: "/dateSheet",
          element: (
            <ProtectedRoute
              element={<DateSheet />}
              requiredPermissions="dateSheet"
            />
          ),
        },
        {
          path: "/examplanner",
          element: (
            <ProtectedRoute
              element={<ExamSheetCreator />}
              requiredPermissions="examplanner"
            />
          ),
        },
        {
          path: "/assignrole",
          element: (
            <ProtectedRoute
              element={<AssignRole />}
              requiredPermissions="examplanner"
            />
          ),
        },
        {
          path: "/admissionForm",
          element: (
            <ProtectedRoute
              element={<AdmissionTab />}
              requiredPermissions="admission"
            />
          ),
        },
        {
          path: "/feeCollection",
          element: (
            <ProtectedRoute
              element={<FeeCollection />}
              requiredPermissions="feeCollection"
            />
          ),
        },

        {
          path: "AssessmentAttempt/attemptSummary",
          element: (
            <ProtectedRoute
              element={<QuizAttemptSummary />}
              requiredPermissions="attemptSummary"
            />
          ),
        },

        {
          path: "/GetCourseContent/modAccessInformation",
          element: (
            <ProtectedRoute
              element={<ModAccessInformation />}
              requiredPermissions="studentQuiz"
            />
          ),
        },
        {
          path: "GetCourseContent/modAccessInformation/quizstudentlist",
          element: (
            <ProtectedRoute
              element={<QuizStudentList />}
              requiredPermissions="studentQuiz"
            />
          ),
        },

        //Admin and principal user router
        {
          path: "/adminAttendance",
          element: (
            <ProtectedRoute
              element={<StaffAttendance />}
              requiredPermissions="attendance"
            />
          ),
        },
        {
          path: "/Planner",
          element: (
            <ProtectedRoute
              element={<AnnualPlanner />}
              requiredPermissions="attendance"
            />
          ),
        },
        {
          path: "/user-reports",
          element: (
            <ProtectedRoute
              element={<UserReports />}
              requiredPermissions="attendance"
            />
          ),
        },
        {
          path: "/createModule",
          element: (
            <ProtectedRoute element={<CreateModuleForm />}  requiredPermissions="module"/>
          ),
        },
        {
          path: "/module",
          element: (
            <ProtectedRoute element={<Module />} requiredPermissions="module" />
          ),
        },

        {
          path: "/user-certificate",
          element: (
            <ProtectedRoute
              element={<UserCertificate />}
              requiredPermissions="attendance"
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <Page404 />,
    },
    {
      path: "/Unauthorized",
      element: <Unauthorized />,
    },
  ]);
  return routes;
};
