// EnglishLanguage.js
const EnglishLanguage = {

        "login": "Login",
        "download": "Download",
        "username": "Username",
        "password": "Password",
        "forgetpassword": "Forgot Password",
        // ===========================
        "dashboard": "Dashboard",
        "bookmarks": "Bookmarks",
        "settings": "Settings",
        // ===========================        
        "mycourses": "My Courses",
        "myclasses": "My Classes",
        "myattendance": "My Attendance",
        // ===========================
        "recentactivities": "Recent Activities",
        "norecentactivities": "No Recent Activities",
        "recentcourses": " Recent Courses",
        "norecentcourses": "No Recent Courses",
        "recentassignments": " Recent Assignments",
        "norecentassignments": "No Recent Assignments",
        "class": "Class",

        // calendar
        "mycalendar": "My Calendar",
        "newevent": "New Event",
        "eventtitle": "Event Title",
        "eventdate": "Date",
        "eventtype": "Type of Event",
        "eventdescription": "Description",
        "eventlocation": "Location",
        "eventduration": "Duration",
        // ===========================
        "save": "Save",
        "delete": "Delete",
        "edit": "Edit",
        "close": "Close",
        "cancel": "Cancel",
        "showmore": "Show More",
        "back": "Back",
        // ===========================
        "quiz": "Assessment",

        //home page section folder
        "Downloadbutton": " Download",
        "learnMore": "Learn More",

        //Footer Folder
        "readMore": "Read More",
        "visitUs": "Visit Us",
        "aboutUs": "About Us",
        "qualityPolicy": "Quality Policy",
        "info": "Info",
        "contactUs": "Contact Us",

        //popup  create events 
        "createNewEvent": "Create New Event",
        "createEvent": "Create Event",
        "eventDate": "Date",
        "eventDateUntil": "Date Until",
        "durationInMinutes": "Duration in minutes",
        "description": "Description",
        "minutes": "Minutes",
        "repeatThisEvent": "Repeat this event",
        "repeatWeeklyEvent": "Repeat Weekly Event",

        //popup get event details 
        "date": "Date",
        "userName": "User Name",
        "deleteAllEvent": "Delete All Event",
        "areYouSure": "Are you sure",

        // Assessment Attempt
        "previous": "Previous",
        "next": "Next",
        "QuizNavigation": "Quiz Navigation",

        // create Calendar Event
        "Typeofevent": "Type of event",
        "course": "Course",
        "MySelf": "My Self",

        //user Popup
        "profile": "Profile",
        "grades": "Grades",
        "calendar": "Calendar",
        "messages": "Messages",
        "preferences": "Preferences",
        "Logout": "Log out",
        "YouAreloggedInAs": "You are logged in as ",

        // user Profile
        "Userdetails": "User details",
        "editprofile": "edit profile",
        "Emailaddress": "Email",
        "Citytown": "City/Town",
        "Loginactivity": "Login activity",
        "Firstaccesstosite": "First access to site",
        "Lastaccesstosite": "Last access to site",
        "Coursedetails": "Course details",

        // report
        "report": "Report",
        "finished": "Submitted",
        "name": "Name",
        "notattempted": "Not Submitted",
        "review": "Review",
        "emailAddress": "E-mail",
        "status": "Status",
        "started": "Started on",
        "completed": "Completed On",
        "timetaken": "Time taken",
        "grade": "Grade %",
        "marks": "Marks/",
        "q1": "Q. 1",
        "q2": "Q. 2",
        "q3": "Q. 3",
        "q4": "Q. 4",
        "q5": "Q. 5",
        "q6": "Q. 6",
        "q7": "Q. 7",
        "q8": "Q. 8",

        "q9": "Q. 9",
        "q10": "Q. 10",
        "q11": "Q. 11",

        "q12": "Q. 12",
        "q13": "Q. 13",

        "q14": "Q. 14",
        "q15": "Q. 15",
        //question 
        "question": "Question",
        "answered": "Answered",
        "correct": "Correct",
        "wrong": "Incorrect",
        "notatt": "Not Attempted",
        "mark": "Mark 1 out of 1",
        "quizNavigation": "Quiz Navigation",

        // Attendance
        "Totaldays": "Days",
        "Totalpresent": "Presents",
        "Totalabsent": "Absents",
        "Totalleaves": "Leaves",
        "markattendance": "Mark Attendance",


        //Admission Form Page
        "fname": "First Name",
        "lname": "Last Name",
        "email": "Email",
        "image": "Upload Image",
        "admissionform": "Admission Form",
        "admissionno": "Admission No.",
        "studentdetail": "Student's Details",
        "dob": "Date of Birth",
        "gender": "Gender",
        "female": "Female",
        "male": "Male",
        "other": "Other",
        "category": "Category",
        "general": "General",
        "obc": "OBC",
        "sc": "SC",
        "st": "ST",
        "religion": "Religion",
        "hindu": "Hindu",
        "muslim": "Muslim",
        "christian": "Christian",
        "aadhar": "Upload Aadhar Card",
        "photoupload": "Upload Photo",
        "document": "Upload Document",
        "address": "Address",
        "permanent": "Permanent Address",
        "current": "Current Address",
        "addressline1": "Address Line 1",
        "addressline2": "Address Line 2",
        "city": "City",
        "state": "State",
        "pincode": "Pincode",
        "country": "Country",
        "birthCertificate": "Upload Birth Certificate",
        "parentdetails": "Parent's Details",
        "mothername": "Mother Name",
        "motheroccupation": "Mother Occupation",
        "motherphone": "Mother's Phone number",
        "fathername": "Father Name",
        "fatheroccupation": "Father Occupation",
        "fatherphone": "Father 's Phone number",
        "documents": "Documents",
        "selectBoth": "Check if both address are same",
        "submit": "submit",
        "blood": "Blood Group",
        "blood1": "O+",
        "blood2": "O-",
        "blood3": "A+",
        "blood4": "A-",
        "blood5": "B+",
        "blood6": "B-",
        "blood7": "AB+",
        "blood8": "AB-",
        "nationality": "Nationality",
        "ews": "Economically Weaker Section (EWS)?",
        "sibling": "Sibling in same school?",
        "transport": "Transport Required?",
        "guardian": "Guardian Name",
        "relation": "Relationship with child",
        "schollname": "Last School Name",
        "schoolAddress": "Last School Address",
        "physical": "Specially Abled",
        "mentally": "Mentally Challenged?",
        "illness": "Any serious illness?",
        "descriptionIllness": "Description of Illness",
        "ewscertificate": "Upload EWS Certificate",
        "certificate": "Certificate",
        "section": "Section",
        "feereceipt": "Fee Receipt",
        "imgsize": "Img size should be 120*120px min",
        "pancard": "upload pan card",
        "salutation": "Salutation",
        "fullname": "Full Name",
        "highqualification": "High Qualification",
        "occupation": "Occupation",
        "annualIncome": "Annual Income",
        "officeAddress": "Office Address",
        "phoneNumber": "Phone Number",
        "mobile": "Mobile",
        "contactNo": "Contact No",
        "Parentsdetail": "Parents Details",
        "registrationNo": "Registration No",

        //Notes Page
        "mynotes": "My Notes",
        "title": "Title",
        "createdOn": "Created On",
        "modifiedOn": "Modified On",
        "action": "Action",

        //List items
        "mystuff": "My stuff",
        "classes": "Classes",
        "attendance": "Attendance",
        "notes": "Notes",
        "fees": "Fees",
        "admission": "Admission",
        "courses": "Courses",

        //Fee Collection
        "feecollection": "Fee Collection",
        "studentname": "Student Name",
        "createcertificate": "Create Certificate",
        "viewcertificate": "View Certificate",
        "search": "Search",
        "notify": "Notify",
        "receivefee": "Receive Fee",

        //Create Certificate 
        "create": "Create",
        "enter": "Enter",
      "certificaterewards":"Certificate Rewards",



        //Exam Planner
        "examPlanner": "Planner",
        "timeTable": "Timetable",
        "annualPlanner": "Annual Planner",
        "usermgmt": "User Management",

  };
  
  export default EnglishLanguage;
  

  
