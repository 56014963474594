import { Box, Typography } from "@mui/material";
import React from "react";
import "./CardSecondary.scss";

const CardSecondary = ({ curLanguage, children, customClassName }) => {
  return (
    <>
      <Box
        className={`recentActivity recentSec ${customClassName}`}
        sx={{ backgroundColor: "darkblue.color" }}
      >
        <Typography
          className="recentItemHeader mainHeading"
          sx={{
            color: "recent.titleColor",
            backgroundColor: "secondaryColor.main",
          }}
        >
          {curLanguage}
        </Typography>
        <Box className="recentList">{children}</Box>
      </Box>
    </>
  );
};
export default CardSecondary;
