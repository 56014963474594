// CustomDialog.js

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const CustomDialog = ({
  isOpen,
  onClose,
  children,
  className,
  width,
  disableBackdropOption,
  closeIcon,
  dialogTitle,
  actionBtn,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className={className}
      maxWidth={width}
      fullWidth={true}
      disablebackdropclick={disableBackdropOption}
    >
      {closeIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoCloseCircleOutline size={40} color="#2769aa" />
        </IconButton>
      )}
     {dialogTitle && <DialogTitle
        id={`id${className}`}
        sx={{
          backgroundColor: "popup.titleHeaderColor",
          color: "popup.popupheadertextcolor",
          mb: 4,
        }}
      >
        {dialogTitle}
      </DialogTitle>}
      <DialogContent
        sx={{
          backgroundColor: "cardColor.main",
          color: "cardColor.contrast",
          padding: 0,
          overflowY:'visible'
        }}
        className="content-div-dialog"
      >
        {children}
      </DialogContent>
      {actionBtn && <DialogActions sx={{justifyContent:{xs:'Center', sm:'flex-end', flexWrap:'wrap'}}} className="modal-btn-wrap-div">{actionBtn}</DialogActions>}
    </Dialog>
  );
};

export default CustomDialog;
