import { Box, Container, Grid, Typography } from "@mui/material";
import FeatureCard from "../../_Components/Common/Cards/FeatureCard";

const Features = () => {
  return (
    <Container maxWidth="xl">
      <Box className="featureWrapper spacing">
        <Typography variant="h2" align="center" className="title">
          What We Provide
        </Typography>
        <Typography variant="h5" align="center" className="subTitle">
          Teacher Dashboard
        </Typography>
        <Box sx={{marginTop:'40px',}}>
          <Grid container spacing={4} className="features">
            <FeatureCard
              className="teacherFeature" lgSize={2} mdSize={4} sxSize={6}
              cardHeading="Digital Content"
              cardIcons="eBook.png"
              altTxt="digital-content"
            >
              <Typography>
                Where ebooks and interactive activities make learning FUN!
              </Typography>
            </FeatureCard>

            <FeatureCard
              className="teacherFeature" lgSize={2} mdSize={4} sxSize={6} 
              cardHeading="Assignments"
              cardIcons="assignment.png"
              altTxt="assignment"
            >
              <Typography>
                Effortlessly assign, assess and plan from one dashboard
              </Typography>
            </FeatureCard>

            <FeatureCard
              className="teacherFeature" lgSize={2} mdSize={4} sxSize={6}
              cardHeading="Attendance"
              cardIcons="attendance.png"
              altTxt="attendance"
            >
              <Typography>
                Mark attendance easily, download Excel sheets
              </Typography>
            </FeatureCard>

            <FeatureCard
              className="teacherFeature" lgSize={2} mdSize={4} sxSize={6}
              cardHeading="Reports"
              cardIcons="reports.png"
              altTxt="reports"
            >
              <Typography>
                Track progress and improvement areas with Excel reports!
              </Typography>
            </FeatureCard>

            <FeatureCard
              className="teacherFeature" lgSize={2} mdSize={4} sxSize={6}
              cardHeading="Notes"
              cardIcons="notes.png"
              altTxt="notes"
            >
              <Typography>
                Keep teaching notes organised and consistent
              </Typography>
            </FeatureCard>

            <FeatureCard
              className="teacherFeature" lgSize={2} mdSize={4} sxSize={6}
              cardHeading="Certificate"
              cardIcons="certificate.png"
              altTxt="certificate"
            >
              <Typography>
                Create student certificates with fun templates!
              </Typography>
            </FeatureCard>
          </Grid>
        </Box>

        {/* Student Feature */}

        <Typography variant="h6" align="center" className="subTitle" mt={6}>
          Student Dashboard
        </Typography>
        <Box sx={{marginTop:'40px',}}>
          <Grid container spacing={4} className="features">
            <FeatureCard
              className="stdFeature" lgSize={2.4} mdSize={4} sxSize={6}
              cardHeading="Digital Assets"
              cardIcons="eBook.png"
              altTxt="digital-content"
            >
              <Typography>
                Dive into learning with ebooks, activities and videos
              </Typography>
            </FeatureCard>

            <FeatureCard
              className="stdFeature" lgSize={2.4} mdSize={4} sxSize={6}
              cardHeading="Assignments"
              cardIcons="assignment.png"
              altTxt="assignment"
            >
              <Typography>
                Complete exercises, submit work and follow Teacher’s plans
              </Typography>
            </FeatureCard>

            <FeatureCard
              className="stdFeature" lgSize={2.4} mdSize={4} sxSize={6}
              cardHeading="Reports"
              cardIcons="reports.png"
              altTxt="reports"
            >
              <Typography>
                Gain insights, track progress with downloadable Excel reports!
              </Typography>
            </FeatureCard>

            <FeatureCard
              className="stdFeature" lgSize={2.4} mdSize={4} sxSize={6}
              cardHeading="Notes"
              cardIcons="notes.png"
              altTxt="notes"
            >
              <Typography>
                Simplify learning with organised study notes.
              </Typography>
            </FeatureCard>
            <FeatureCard
              className="stdFeature" lgSize={2.4} mdSize={4} sxSize={6}
              cardHeading="Certificate"
              cardIcons="certificate.png"
              altTxt="certificate"
            >
              <Typography>
                Receive certificates with engaging templates!
              </Typography>
            </FeatureCard>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Features;
