import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ExamSheetList from "./ExamSheetList";
import MainCard from "../../_Components/Common/Cards/MainCard";
import ExamSheetCreator from "./ExamSheetCreator";
import { Grid } from "@mui/material";
import CalendarData from "../calendar/CalendarData";
import { Scrollbar } from "react-scrollbars-custom";
import "./AnnualPlanner.scss";
import { useState } from "react";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AnnualPlanner() {
  const [value, setValue] = useState(0);
  const [refreshEvent, setRefreshEvent] = useState()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEvent = () => {
    console.log("Event triggered");
    setRefreshEvent(true)
  }

  return (
    <MainCard customClass="annualPlanner" cardTitle={"Planner"}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Exam Planner" {...a11yProps(0)} />
          <Tab label="Event Planner" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Scrollbar style={{ width: "100%", height: `calc(70vh - 40px)` }}>
        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <ExamSheetCreator onUpdatingEvent={handleEvent} />
            </Grid>
            <Grid item md={4}>
              <ExamSheetList refreshEvent={refreshEvent}/>
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className="eventPlanner calendar-container ">
            <CalendarData />
          </Box>
        </CustomTabPanel>
      </Scrollbar>
    </MainCard>
  );
}
