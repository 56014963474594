import { Box, Typography , useMediaQuery} from "@mui/material";

const SliderContent = ({
  sliderImg,
  sliderImgMb,
  content,
  altTxt,
  customClass,
  subText,
}) => {
  const smBreakPoint = useMediaQuery('(max-width:414px)');
  return (
    <Box className="sliderSection">
      {smBreakPoint? <img
        src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/slider/${sliderImgMb}`}
        alt={altTxt}
      /> : <img
      src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/slider/${sliderImg}`}
      alt={altTxt}
    />}
      <Box className="contentContainer">
        <Box className={customClass}>
          <Typography variant="h1" className="heading">
            {content}
          </Typography>
          {subText && (
            <Typography variant="h3" className="subHeading">
              {subText}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SliderContent;
