import { Tooltip } from "@mui/material";
import React from "react";

const UploadImage = ({ mssg }) => {
  return (
    <Tooltip title={mssg} arrow>
      <img
        src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/upload.png`}
        alt="uploadImg"
        width={55}
        title={mssg}
      />
    </Tooltip>
  );
};

const DownloadImage = ({ mssg }) => {
  return (
    <Tooltip title={mssg} arrow>
      <img
        src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/download.png`}
        alt="downloadImg"
        width={55}
      />
    </Tooltip>
  );
};

export { UploadImage, DownloadImage };
