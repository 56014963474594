import CreateModuleForm from "../../_Components/Common/ReportCard/CreateModuleForm";
import TeacherModule from "../../_Components/Common/ReportCard/CreateTeacherModule";
import { usePermissions } from "../../_Components/Permission/PermissionsContext";

const Module = () => {
  const { permissions } = usePermissions();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return <>
    {permissions.module?.write && permissions.module?.read && <CreateModuleForm />}
    {/* {!permissions.module?.write && permissions.module?.read && } */}
    {permissions.module?.read && userInfo[0].department.toLowerCase() === "pedagogy" && <TeacherModule />}
    {permissions.module?.read && userInfo[0].department.toLowerCase() === "student" && "for student"}
  </>;
};

export default Module;
