import { Box, Typography } from "@mui/material";
import { AiOutlineInfoCircle } from "react-icons/ai";

const InfoIcon = ({ mssg }) => {
  return (
    <Box display={"flex"} alignItems="center">
      <AiOutlineInfoCircle color={"primary.main"} size={22}/>
      <Typography><b>{mssg}</b></Typography>
    </Box>
  );
};

export default InfoIcon;
